import React from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { TransitionState } from 'gatsby-plugin-transition-link'

import Layout from '@components/Layout'
import { LAYOUT_STYLE } from '../Layout/constants'
import { richTextOptions } from '@components/Markdown'

const LegalPage = ({ data }) => {
  const buildContent = node => {
    return documentToReactComponents(node, richTextOptions)
  }

  const rawContent = data.allContentfulLegalPage.edges[0].node
  const heading = rawContent.heading
  const title = ""
  const content = buildContent(rawContent.content.json)

  const links = [
    {
      label: title,
      path: '/legal',
      active: false,
    },
  ]

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => (
        <Layout
          className={transitionStatus}
          layoutStyle={LAYOUT_STYLE['leftNav']}
          seo={{ title }}
          links={links}
          heading={heading}
        >
          {content}
        </Layout>
      )}
    </TransitionState>
  )
}

LegalPage.propTypes = {
  data: PropTypes.object,
}

export default LegalPage
