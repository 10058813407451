import LegalPage from '@components/LegalPage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    allContentfulLegalPage {
      edges {
        node {
          title
          heading
          content {
            json
          }
        }
      }
    }
  }
`

export default LegalPage
